import { defineStore } from 'pinia'

import { Filter, BlogState, Filters, Hit } from '~/js/interface/blog'
import { QueryParams } from '~/interfaces/query-parameters'

import { useSearchParameters, useTypesenseClient } from '~/js/composables/typesense'
import { useRemoveQueryUrlify, useQueryUrlify, useQueryArrayUrlify} from '~/js/composables/query-parameters'

export const useBlogStore = defineStore('blogs', {
    state: (): BlogState => ({
        loading: true,
        facets: [],
        filters: {
            searchQuery: '',
            filterQuery: '',
            type: {
                field: 'type',
                value: null,
                combination: '&&'
            },
            sector: {
                field: 'sector',
                value: null,
                combination: '&&'
            },
            timestamp: {
                field: 'post_date_timestamp',
                value: null,
                combination: '&&'
            },
            tags: {
                field: 'tags',
                values: [],
                combination: '&&'
            },
        },
        pagination: {
            current: 0,
            totalPages: 0,
            totalResults: 0,
            foundResults: 0,
            itemsPerPage: 100,
        },
        sorting: {
            type: 'post_date_timestamp',
            order: 'desc',
        },
        index: `g4j_${window.typesense_environment}_blog`,
        blogs: null,
        randoms: null,
        useQueryParams: true,
    }),
    actions: {
        async fetch(reset = false, random = false, queryParams?: Array<QueryParams>) {
            this.loading = true

            // these randoms are if no or less than 5 results are being found, it will show randoms
            const fetchRandoms = this.filters.sector.value != '' || this.filters.tags.values.length > 0

            // set URL query parameters if existing
            if (queryParams) {
                this.setQueryFilters(queryParams)
            }

            if (reset) {
                this.pagination.current = 0
            } else {
                this.pagination.current += 1
            }

            const client = useTypesenseClient()
            const searchParameters = useSearchParameters(this.filters, this.sorting)

            // add default sorting for blogs
            const searchRequests = {
                searches: [
                    {
                        collection: this.index,
                        page: this.pagination.current,
                        facet_by: 'type,sector,tags',
                        per_page: this.pagination.itemsPerPage,
                        ...searchParameters
                    }
                ]
            }

            // if a filter is set (and reset fetch), add a query to fetch all the blog posts. This is to provide generic blogs if no sector is applied to the job
            // if (fetchRandoms && reset) {
            //     searchRequests.searches.push({
            //         sort_by: "_text_match:desc",
            //         collection: this.index,
            //         q: "*",
            //         page: 1,
            //         per_page: this.pagination.itemsPerPage,
            //         filter_by: this.filters.filterQuery.replaceAll(':[',':![').replaceAll('||','&&'), // exclude the sector and tags given
            //     })
            // }

            await client
                .multiSearch
                .perform(searchRequests)
                .then((results: any) => {
                    setTimeout(() =>{
                        
                        // get the facets by the filtered results for updating the blog filters
                        const filteredResults = results.results[0]
                        const facets = filteredResults.facet_counts
                        this.facets = facets

                        // update pagination
                        this.pagination.current = filteredResults.page
                        this.pagination.totalPages =  this.pagination.itemsPerPage ? Math.ceil(filteredResults.found / this.pagination.itemsPerPage) : 0
                        this.pagination.foundResults = filteredResults.found
                        this.pagination.totalResults = filteredResults.out_of

                        // add the documents to blogs (when reset, start from scracht, when load more, add them up)
                        if (filteredResults.hits) {
                            const hits: Array<Hit> = filteredResults.hits

                            if (reset) {
                                this.blogs = hits
                            } else {
                                this.blogs = [...(this.blogs || []), ...hits]
                            }
                        }

                        // set randoms to show aside of the jobs
                        if (reset && random) {
                            let sectorPosts:Array<Hit> = []
                            let randomizePosts:Array<Hit> = []

                            if (this.filters.sector.value) {
                                sectorPosts = filteredResults?.hits.filter(doc => doc.document.sector == this.filters.sector.value).sort( () => Math.random() - 0.5) 
                                randomizePosts = filteredResults?.hits.filter(doc => doc.document.sector == '').sort( () => Math.random() - 0.5) 
                            } else {
                                randomizePosts = [...filteredResults?.hits ?? []].sort( () => Math.random() - 0.5) 
                            }

                            // merge the filtered results with the hits
                            this.randoms = [...sectorPosts ?? [], ...randomizePosts ?? []]
                        }

                        this.loading = false
                    }, 1000)
                })
        },
        setPagination(page:number) {
            this.pagination.current = page
            this.fetch()
        },
        setItemsPerPage(limit:number) {
            this.pagination.itemsPerPage = limit
        },
        setSearch(value: string) {
            this.filters.searchQuery = value

            this.fetch(true)
        },
        setUserCoords(coords) {
            this.coords = coords
            this.fetch(true)
        },
        setFilter(type: keyof Filters, value: string|Array<string>|null, fetch = true, random = false) {
            const settedFilter: Filter | string = this.filters[type] ?? null

            if (typeof settedFilter !== 'string') {
                if (settedFilter.hasOwnProperty('value')) {

                    if (this.useQueryParams) {
                        // update query
                        if (value == '') {
                            useRemoveQueryUrlify(type, settedFilter.value)
                        } else {
                            useQueryUrlify(type, value)
                        }
                    }

                    settedFilter.value = value
                }

                if (settedFilter.hasOwnProperty('values')) {
                    if (settedFilter.values.indexOf(value) < 0) {
                        settedFilter.values.push(value)
                        if (this.useQueryParams) {
                            useQueryArrayUrlify(type, value)
                        }
                    } else {
                        settedFilter.values = settedFilter.values.filter((item: string) => item !== value)
                        if (this.useQueryParams) {
                            useRemoveQueryUrlify(type, value)
                        }
                    }
                }
            }

            this.setFiltersQuery()

            if (fetch) {
                this.fetch(true, random)
            }
        },
        setFiltersQuery() {
            this.filters.filterQuery = ''

            if (this.filters.type.value) {
                this.filters.filterQuery += this.filters.type.field + ':[' + this.filters.type.value + '] ' + this.filters.type.combination + ' '
            }

            if (this.filters.sector.value) {
                const combination = this.filters.tags.values.length > 0 ? '||' :  this.filters.sector.combination
                this.filters.filterQuery += this.filters.sector.field + ':[' + this.filters.sector.value + '] ' + combination + ' '
            }

            if (this.filters.tags.values.length > 0) {
                this.filters.filterQuery += this.filters.tags.field + ':[' + this.filters.tags.values.toString() + '] ' + this.filters.tags.combination + ' '
            }

            if (this.filters.timestamp.value) {
                this.filters.filterQuery += this.filters.timestamp.field + ':[>' + this.filters.timestamp.value + ']'
            }

            // remove empty ' && '
            const pipesCombination = '&& '
            if (this.filters.filterQuery.lastIndexOf(pipesCombination) >= this.filters.filterQuery.length - 4) {
                this.filters.filterQuery = this.filters.filterQuery.substring(0, this.filters.filterQuery.lastIndexOf(pipesCombination))
            }
        },
        setQueryFilters(queryParams: Array<QueryParams>) {
            for (const key in queryParams as QueryParams[]) {
                const values = queryParams[key].values
                let type: string = queryParams[key].type

                if (values) {

                    const filter = this.filters[type as keyof Filters]

                    if (filter) {
                        if (filter.hasOwnProperty('values')) {
                            this.filters[type as keyof Filters].values = values.map((item: { name: string }) => item.name)
                        }

                        if (filter.hasOwnProperty('value')) {
                            if (typeof this.filters[type as keyof Filters].value === 'boolean') {
                                this.filters[type as keyof Filters].value = (values.map((item: { name: string }) => item.name)[0] === 'true')
                            } else {
                                this.filters[type as keyof Filters].value = values.map((item: { name: string }) => item.name)[0]
                            }
                        }

                        if (typeof filter == 'string') {
                            this.filters[type as keyof Filters] = values.map((item: { name: string }) => item.name)[0]
                        }
                    }
                }
            }

            this.setFiltersQuery()
        },
        setQueryParams(set: boolean) {
            this.useQueryParams = set
        }
    }
})